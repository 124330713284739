
.presentation-head {
  display: inline-block;
  border-bottom: none;

  .code-wrapper {
    position: absolute;
    left: 0px;
    height: 100%;
    width: 8px;
    border-radius: 5px;
  }

  .item-header-gradient {
    position: absolute;
    //z-index: -1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: url('../../assets/shadow.png') no-repeat bottom center;
    background-size: cover;
  }

  .title {
    font-size: 1.1rem;
  }

  .type {
    font-size: 0.8rem;
    display: inline-block;
    line-height: 0.8rem;

    strong {
      display: inline-block;
      font-size: 0.7rem;
    }
  }

  .time {
    font-size: 0.8rem;
  }
}

.action-buttons {
  .actn-icon {
    font-size: 1.3rem !important;
  }

  .actn-text {
    font-size: 0.7rem !important;
    line-height: 0.8rem !important;
  }
}


.stream-container {
  width: 70%;
  //max-width: 1100px;

  .stream {
    position: relative;
    width: 100%;
    aspect-ratio: 16/9;

    .info-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 3px dashed grey;

      .info {
        flex: 0 0 400px;
        font-size: 2rem;
        line-height: 2.2rem;
        color: grey;
        font-weight: bold;
      }
    }
  }
}
@media(max-width: 767px) {
  .stream-container{
    width:100%
  }
}

